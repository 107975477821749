<template>
  <v-main>

    <v-container
        id="payment-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-cash-multiple"
          class="px-5 py-3"
          ref="form"
      >

        <div>
          <v-alert
              type="error"
              outlined
              v-for="(error, i) in errors"
              :key="i"
          >
            {{ error }}
          </v-alert>
        </div>

        <v-form ref="paymentForm"
                v-model="valid"
                @submit.prevent="validate"
                lazy-validation
                v-if="invoice.id"
        >

          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="invoiceId"
                  label="Invoice #"
                  disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="computedTotal"
                  label="Invoice Amount"
                  disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="computedBalance"
                  label="Invoice Balance"
                  disabled
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="payment.amount"
                  label="Amount (XXX.XX)"
                  id="paymentAmount"
                  :rules="rules.paymentAmount"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                sm="6"
            >
              <v-text-field
                  v-model="payment.date"
                  label="Payment Date"
                  id="paymentDate"
                  type="date"
                  :rules="rules.required"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <v-select
                  v-model="payment.method"
                  label="Payment Method"
                  id="paymentMethod"
                  :items="paymentMethods"
                  :rules="rules.required"
              ></v-select>
            </v-col>

          </v-row>

          <v-row v-if="computedRequireBankBranch">
            <v-col
                cols="12"
                lg="6"
            >
              <v-text-field
                  v-model="payment.bankDepositBranch"
                  label="Wells Fargo Bank Branch"
                  id="bankBranch"
                  :rules="rules.required"
              ></v-text-field>
            </v-col>

          </v-row>

          <v-row v-if="computedRequireBankOrigin">
            <v-col
                cols="12"
                lg="6"
            >
              <v-text-field
                  v-model="payment.bankTransferOrigin"
                  label="Transfer Origin Bank"
                  id="originBank"
                  :rules="rules.required"
              ></v-text-field>
            </v-col>

          </v-row>

          <v-row>
            <v-col
                cols="12"
                lg="6"
            >
              <v-text-field
                  v-model="payment.reference"
                  label="Transaction/Reference #"
                  id="paymentReference"
                  :rules="rules.required"
              ></v-text-field>
            </v-col>

          </v-row>



          <v-row>
            <v-col
                cols="12"
                sm="4"
            >
              <div class="my-2">
                <v-btn
                    color="primary"
                    dark
                    type="submit"
                    id="submitPayment"
                >
                  Submit
                </v-btn>
              </div>
            </v-col>
          </v-row>


        </v-form>


      </base-material-card>

    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import {mapGetters} from 'vuex'
import validation from '@/utils/validation'
import constants from '@/utils/constants'
import router from '@/router'

export default {
  name: 'PaymentView',

  created() {
    this.userId = this.getUserId
    this.invoiceId = this.$route.params.invoiceId
    this.paymentId = this.$route.params.paymentId

    this.fetchInvoice()
  },

  computed: {
    ...mapGetters({
      getUserId: 'getUserId'
    }),

    computedTotal() {
      return this.invoice.total.toFixed(2)
    },

    computedBalance() {
      return this.invoice.balance.toFixed(2)
    },

    computedRequireBankOrigin() {
      return this.payment.method === constants.PAYMENT_METHOD_BANK_TRANSFER_NON_WF
    },

    computedRequireBankBranch() {
      return this.payment.method === constants.PAYMENT_METHOD_BANK_DEPOSIT
    }
  },

  methods: {
    validate() {
      this.messages = []
      this.errors = []
      if(this.$refs.paymentForm.validate()) {
        this.createPayment()
      }
    },

    fetchInvoice() {
      api
          .fetchInvoice(this.userId, this.invoiceId)
          .then(response => {
            this.invoice = response.data
          })
          .catch(err => this.handleError(err))
    },

    createPayment() {
      this.$gtag.event('submit_payment')
      const invoiceId = this.invoice.id
      api
          .createPayment(this.userId, invoiceId, this.payment)
          .then(response => {
            if (response.status === 201) {
              router.push(`/invoice/${invoiceId}`)
            }
          })
          .catch(err => this.handleError(err))
    },

    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }

  },

  data() {
    return {
      valid: true,
      errors: [],
      userId: null,
      invoice: null,
      invoiceId: null,
      paymentId: null,
      payment: {},
      paymentStatusMap: constants.getPaymentStatusMap(),
      paymentMethods: constants.getPaymentMethods(),
      rules: {
        required: [
          v => !!v || 'required'
        ],
        numeric: [
          v => validation.isInteger(v) || 'must be a number'
        ],
        paymentAmount: [
          v => validation.isCurrency(v) || 'Must be in currency format',
          v => validation.isLessThanOrEqual(v, this.invoice.balance) || 'Cannot be more than the balance'
        ]
      }
    }
  }
}
</script>
